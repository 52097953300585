.leftContainer {
    display: flex;
    flex-direction: column;
    gap: 1.538vw;
}

.rightContainer {
    display: flex;
    flex-direction: column;
    gap: 1.538vw;
    align-items: center;
}

.fieldsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2.436vw;
}

.customTextfield {
    box-shadow: 0px 2px 4px 0px #0000001A, 0px 2px 4px 0px #0000001A inset;
    border-radius: 0.513vw !important;
}

.tableHead {
    background-color: #EAF1F7;
    height: 2.821vw;
    border-radius: 0.5vw;
    box-shadow: 2px 2px 4px 0px #AEAEC080;
    margin-bottom: 0.769vw;
}

.mainTable th, td {
    height: 4.295vw;
    border-bottom: 1px solid #313C60;
}

.mainTable {
    margin-top: 3.333vw;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
}

.dropdownOpen {
    display: block;
  }
  
  .dropdownClosed {
    display: none;
  }