.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.769vw;
    font-size: 0.897vw !important;
    white-space: nowrap;
}
.buttonContainer button{
    width: -webkit-fill-available;
}
table{
    width: 100%;
}

.tableHead {
    background-color: #EAF1F7;
    height: 2.821vw;
    border-radius: 0.5vw;
    box-shadow: 2px 2px 4px 0px #AEAEC080;
    margin-bottom: 0.769vw;
}

.tableBody {
    margin-top: 1vw;
}

.mainTable th, td {
    height: 4.295vw;
    border-bottom: 1px solid #313C60;
}

.headerRight {
    display: flex;
}

.dialogTableHead {
    height: 2.308vw;
    background: #EAF1F7;
    box-shadow: 2px 2px 4px 0px #AEAEC080;
}

.formStyles {
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem;
}

.customTextfield {
    box-shadow: 0px 2px 4px 0px #0000001A, 0px 2px 4px 0px #0000001A inset;
    border-radius: 0.513vw !important;
    width: 32.051vw;
    margin-bottom: 1rem;
}
/* For Mobile */
.actionBtn{
    height: 1.25rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    color: black !important;
    text-transform: capitalize !important;
    font-size: 10px !important;
    padding: 0
}
@media (max-width: 768px) {
    .buttonContainer {
        gap: 0.5rem;
    }
}