.warningDialogContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 16.667vw;
  padding-top: 1.41vw;
  padding-left: 0.897vw;
  padding-right: 0.897vw;
  padding-bottom: 1.41vw;
}
/* 
.warningDialogContent div{
    display: flex;
    justify-content: center;
} */

.warningIconContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 1.282vw;
}

.warningIconContainer img{
    width: 1.795vw;
    height: 1.795vw;
}

.warningTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.warningTextContainer h2 {
  /* font-family: Graphik Trial; */
  font-size: 1.154vw;
  font-weight: 500;
  line-height: 1.385vw;
  text-align: left;
  margin-bottom: 0.769vw;
}

.warningTextContainer h5 {
  /* font-family: Graphik Trial; */
  font-size: 0.769vw;
  font-weight: 400;
  line-height: 0.923vw;
  text-align: left;
  margin-bottom: 1.538vw;
}
