.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.inputContainer{
    display: flex;
    flex-direction: column;
}

.textInputContainer{
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 2vw;
    align-items: center;
}

.mainForm{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.buttonContainer{
    display: flex;
    justify-content: space-around;
}

.errorText{
    color: red;
    font-size: 0.8rem;
}

.dialogClass{
    width: 60vw;
    margin: auto;
}

.dialogContainer{
    width: 100%;
    padding: 1rem;
}

th{
    text-align: center;
}

td{
    text-align: center;
}

.buttonsContainer{
    display: flex;
    flex-direction: row;
    gap: 1rem   ;
    justify-content: center;
    overflow: hidden;
}
/* Mobile CSS */
.actionBtn{
    width: 5rem;
    height: 1.25rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
}