  .page-title {
    font-size: 1.795vw;
    font-weight: 600;
    line-height: 2.154vw;
    text-align: left;
    margin-bottom: 1.538vw;
  }

  .dashboard-card-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1.026vw;
    margin-bottom: 2.308vw;
  }

  .dashboard-card {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EAF1F7;
    width: 11.538vw;
    height: 6.41vw;
    border-radius: 0.256vw;
    box-shadow: 2px 2px 8px 0px #AEAEC080;
    cursor: pointer;
    padding: 1.538vw;
  }

  .card-title {
    font-size: 1.026vw;
    font-family: 'Franklin Gothic Small', 'Arial', Arial, sans-serif;
    margin-bottom: 0%;
    text-align: center;
  }

  .subtitle {
    font-family: 'Franklin Gothic Small', 'Arial', Arial, sans-serif;
    font-size: 1.282vw;
    font-weight: 600;
    line-height: 1.282vw;
    text-align: left;
    margin-bottom: 1.026vw;
  }

/* For Mobile View */
@media (max-width: 768px) {
  .page-title {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.35rem;
    margin-bottom: 3.063rem;
  }

  .dashboard-card-container {
    gap: 0.5rem;
    margin-bottom: 1.75rem;
  }

  .dashboard-card {
    width: 7rem;
    height: 3.75rem;
    border-radius: 0.25rem;
    /* padding: 1.438rem 1.563rem; */
  }

  .card-title {
    font-size: 0.75rem;
  }

  .subtitle {
    font-size: 0.875rem;
    line-height: 1.05rem;
    margin-bottom: 0.5rem;
  }
}