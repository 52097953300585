*{
    box-sizing: border-box;
}

.parentContainer{
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 3vw;
}

.button{
background: #37446F;

}

.mainContainer{
    width: 32.051vw;
    /* height: 22.564vw; */
}

.stylesContainer{
    font-weight: 500;
}

.inputs{
    /* border: 1px solid black; */
    box-shadow: 0px 2px 4px 0px #0000001A;
box-shadow: 0px 2px 4px 0px #0000001A inset;
background: #FFFFFF;

    
}