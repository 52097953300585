*{
    box-sizing: border-box;
}
::-ms-reveal{
    display: none;
}
p{
    margin: 0;
    padding: 0;
}

html,body{
    height: 100%;
}

body{
    padding:1px;
}

#root{
    height: 95%;
    padding:1px;
}

.table thead tr:first-child th {
    border-top: none;
}

.button {
    color: black !important;
    text-transform: capitalize !important;
}

.button-two {
    color: white !important;
    text-transform: capitalize !important;
}

.fieldContainer {
    display: flex;
    flex-direction: row;
    gap: 1.538vw;
}
.leftFields {
    display: flex;
    flex-direction: column;
}
.rightFields {
    display: flex;
    flex-direction: column;
}
.customTextfield {
    box-shadow: 0px 2px 4px 0px #0000001A, 0px 2px 4px 0px #0000001A inset;
    border-radius: 0.513vw !important;
    width: 32.051vw !important;
    margin-bottom: 1rem;
}
.customTextfieldMobile{
    box-shadow: 0px 2px 4px 0px #0000001A, 0px 2px 4px 0px #0000001A inset;
    border-radius: 0.513vw !important;
    margin-bottom: 1rem;
}
.outlined-button {
    height: 2.564vw !important;
    width: 15.385vw !important;
    border-radius: 4px !important;
    text-transform: capitalize !important;
    color: #000000 !important;
    border: 2px solid #313C63 !important;
}
.filled-button {
    height: 2.564vw !important;
    width: 15.385vw !important;
    border-radius: 4px !important;
    text-transform: capitalize !important;
    color: #fff !important;
    background-color: #37446F !important;
}
.sub-title {
    font-size: 1.282vw;
    font-weight: 600;
    margin-bottom: 1.538vw;
}
.table-head {
    height: 44px; 
    border-radius: 4px !important;
    background: rgba(234, 241, 247, 1);
    box-shadow: 2px 2px 4px 0px rgba(174, 174, 192, 0.5)
}
.page-wrapper {
    margin: 6.923vw 1.538vw 0 1.538vw;
}
.page-header {
    font-size: 1.795vw;
    font-weight: 600;
}
.top-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2.179vw;
    align-items: center;
}
.tableHead {
    background-color: #EAF1F7;
    height: 2.821vw;
    border-radius: 0.5vw;
    box-shadow: 2px 2px 4px 0px #AEAEC080;
    margin-bottom: 0.769vw;
}
.mainTable th, td {
    height: 4.295vw;
    border-bottom: 1px solid #313C60;
}
.mainTable {
    margin-top: 3.333vw;
}
.dialog-fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.dialog-actions-box {
    display: flex !important;
    justify-content: center !important;
    gap: 1vw;
}

.g-but {
    height: 2.051vw !important;
    width: 7.179vw !important;
    box-shadow: 2px 2px 4px 0px #AEAEC080 !important;
    text-transform: capitalize !important;
    color: #000 !important;
    background-color: #D1F4DE !important;
}
.b-but {
    height: 2.051vw !important;
    width: 7.179vw !important;
    box-shadow: 2px 2px 4px 0px #AEAEC080 !important;
    text-transform: capitalize !important;
    color: #000 !important;
    background-color: #CBEBF6 !important;
}
.y-but {
    height: 2.051vw !important;
    width: 7.179vw !important;
    box-shadow: 2px 2px 4px 0px #AEAEC080 !important;
    text-transform: capitalize !important;
    color: #000 !important;
    background-color: #FCF6BC !important;
}
.r-but {
    height: 2.051vw !important;
    width: 7.179vw !important;
    box-shadow: 2px 2px 4px 0px #AEAEC080 !important;
    text-transform: capitalize !important;
    color: #000 !important;
    background-color: #FFD6D2 !important;
}

.dialog-title {
    font-size: 1.154vw;
    font-weight: 600;
}

.descriptionContainer{
    grid-row: 3/5;
    grid-column: 2/3;
    height: 100%;
}

/* For Mobile */
.formBtnMobile{
    width: 11.75rem;
    height: 2.25rem;
    border-radius: 0.366rem;
    text-transform: capitalize !important;
}

/* Skills Button */
.cancelMobileButtonStyles{
    width: 11.75rem !important;
    height: 2.25rem !important;
    background: none !important;
    border: 2.03px solid #37446F !important;
    color: black !important;
    margin-right: 8px !important;
    text-transform: none !important;
}
.addMobileButtonSytles{
    width: 11.75rem !important;
    background: #37446F !important;
    height: 2.25rem !important;
}
.actionMobileBtn{
    width: 5rem;
    height: 1.25rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    text-transform: none !important;
    font-size: 0.625rem !important;
}

@media screen and (max-width: 768px) {
    .page-header {
        font-size: 1.125rem !important;
        font-weight: 500;
        margin-bottom: 2.25rem;
    }
    .fieldContainer {
        flex-direction: column;
        gap: 20px;
    }
    .leftFields, .rightFields {
        flex-direction: column;
        width: 100%;
        gap: 1rem;
    }
    .customTextfield {
        box-shadow: 0px 2px 4px 0px #0000001A, 0px 2px 4px 0px #0000001A inset;
        border-radius: 0.5rem !important;
        width: 91.175vw !important;
        margin-bottom: 1rem;
    }
    .outlined-button {
        height: 2.25rem !important;
        width: 11.75rem !important;
        border-radius: 5.85px !important;
        text-transform: capitalize !important;
        color: #000000 !important;
        border: 2px solid #313C63 !important;
    }
    .filled-button {
        height: 2.25rem !important;
        width: 11.75rem !important;
        border-radius: 5.85px !important;
        text-transform: capitalize !important;
        color: #fff !important;
        background-color: #37446F !important;
    }
    .full-but {
        height: 2.25rem !important;
        width: 91.175vw !important;
        border-radius: 5.85px !important;
        text-transform: capitalize !important;
        color: #fff !important;
        background-color: #37446F !important;
    }
    .tableHead {
        background-color: #EAF1F7;
        height: 2rem;
        border-radius: 0.4rem;
        box-shadow: 2px 2px 4px 0px #AEAEC080;
        /* margin-bottom: 0.769vw; */
    }
    .mainTable th, td {
        height: 2.313rem;
        border-bottom: 1px solid #313C60;
    }
    .filled-sm {
        height: 2.25rem !important;
        width: 6rem !important;
        border-radius: 5.85px !important;
        text-transform: capitalize !important;
        color: #fff !important;
        background-color: #37446F !important;
    }
    .top-div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1rem;
        align-items: center;
    }
    .g-but {
        height: 1.25rem !important;
        width: 5rem !important;
        text-transform: capitalize !important;
        color: #000 !important;
        background-color: #D1F4DE !important;
        font-size: 0.75rem !important;
    }
    
    .b-but {
        height: 1.25rem !important;
        width: 5rem !important;
        text-transform: capitalize !important;
        color: #000 !important;
        background-color: #CBEBF6 !important;
        font-size: 0.75rem !important;
    }
    
    .y-but {
        height: 1.25rem !important;
        width: 5rem !important;
        text-transform: capitalize !important;
        color: #000 !important;
        background-color: #FCF6BC !important;
        font-size: 0.75rem !important;
    }
    
    .r-but {
        height: 1.25rem !important;
        width: 5rem !important;
        text-transform: capitalize !important;
        color: #000 !important;
        background-color: #FFD6D2 !important;
        font-size: 0.75rem !important;
    }
    .mainTable th, td {
        height: 2.75rem;
        border-bottom: 1px solid #313C60;
        font-size: 0.75rem;
    }
    .dialog-field {
        box-shadow: 0px 2px 4px 0px #0000001A, 0px 2px 4px 0px #0000001A inset;
        border-radius: 0.5rem !important;
        width: 90.291vw !important;
        margin-bottom: 1rem;
    }
    .dialog-actions-box {
        display: flex;
        justify-content: space-between;
        gap: 0.625rem;
    }
    .add-skill-but {
        height: 2.25rem !important;
        width: 89.32vw !important;
        border-radius: 5.85px !important;
        text-transform: capitalize !important;
        color: #fff !important;
        background-color: #37446F !important;
    }
}