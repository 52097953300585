.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}
.pageHeader{
    display: flex;
    align-items: center;
    margin: 0;
    gap: 0.6vw;
}
.buttonContainer {
    display: flex;
    gap: 1rem;
}
table{
    width: 100%;
}

.tableHead {
    background-color: #EAF1F7;
    height: 2.821vw;
    border-radius: 0.5vw;
    box-shadow: 2px 2px 4px 0px #AEAEC080;
    margin-bottom: 0.769vw;
}

.tableBody {
    margin-top: 1vw;
}

.mainTable th, td {
    height: 4.295vw;
    border-bottom: 1px solid #313C60;
}

.headerRight {
    display: flex;
}

.dialogTableHead {
    height: 2.308vw;
    background: #EAF1F7;
    box-shadow: 2px 2px 4px 0px #AEAEC080;
}

.formStyles {
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem;
}

.customTextfield {
    box-shadow: 0px 2px 4px 0px #0000001A, 0px 2px 4px 0px #0000001A inset;
    border-radius: 0.513vw !important;
    width: 32.051vw;
    margin-bottom: 1rem;
}

.dialogContentContainer{
    display: grid;
    grid-template-columns: 32.051vw 32.051vw;
    grid-auto-rows: max-content;
    gap: 1.538vw;
}

.shortDescriptionContainer{
    height: 7.949vw;
    grid-column: 1/2;

}

    .descriptionContainer{
        grid-row: 3/5;
        grid-column: 2/3;
        height: 100%;
    }

.summaryContainer{
    height: 7.949vw;
    grid-column: 1/2;
}

.subtitleContainer{
    height: 19vw;
}

.subDescriptionContainer{
    height: 60%;
}

.lowerContainer{
    margin-top: 1vw;
    display: flex;
    justify-content: center;
}

.lowerContainer button{
    margin-right: 1vw;
}