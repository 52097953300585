.fieldContainer {
    width: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1vw;
    row-gap: 1vw;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    margin-top: 1vw;
    /* justify-content: center; */
}
.reportHeader{
    max-width: fit-content;
    margin: auto 15.513vw;
}