.container {
    background-color: white;
  }
  
  .page {
    background-color: white;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    background-color: white;
    color: black;
    border-bottom: 1px solid black;
  }
  
  .title {
    text-align: center;
    color: black;
    margin: 5px 0 5px 0 !important;
    font-weight: bolder !important;
  }
  
  .right {
    display: flex;
    align-items: center;
  }
  
  .right img {
    margin-right: 10px;
  }
  
  .right p {
    margin: 0;
    margin-right: 10px;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    align-items: center;
  }
  
  .box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 1rem 1.25rem 1rem 1.25rem;
  }
  
  .userinfo {
    margin: 0.3125rem 0.625rem 0.3125rem 0.625rem !important;
    display: flex;
    flex-direction: row;
    gap: 140px;
    color: #333;
  }
  
  .infoBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 0.75rem;
    font-weight: 500;
  }
  
  .scoreBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #333;
    padding: 0 32px 0 48px;
    line-height: 20px;
    border-left: 1px solid #d9d9d9;
  }
  
  .row {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-bottom: 10px;
  }
  
  .row p {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 500;
  }
  
  .chartContainer {
    margin-top: 10px;
  }
  
  .categoryCard {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    margin: 5px;
    background-color: #f8f8f8;
    color: #333;
    font-weight: bold;
  }
  
  .card {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .column {
    display: inline-block;
    vertical-align: top;
    width: 30%;
  }
  
  h3 {
    text-align: center;
  }
  
  p {
    margin: 0;
    padding: 5px 0;
    list-style-type: disc;
  }
  
  .categories {
    display: flex;
    justify-content: space-between;
  }
  
  .footer {
    text-align: center;
    font-size: 14px;
    color: #333;
    padding: 0;
  }