.logoStyle {
    display: flex;
    margin-right: 16px;
    height: 2.308vw;
    width: 12.692vw;
}


.imageContainer{
    height: 2.308vw;
    width: 12.692vw ;
}

.mainContainer{
    height: 4.359vw;
}

.ToolbarContainer{
    min-height: auto !important;
}