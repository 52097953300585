.toolBarComponent{
    width: 100%;
    height: 20%;
    max-height: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5vw;
}

.button{
    height: 1.026vw;
    width: 1.026vw;
    position: static;
    background-color: transparent;
    display: inline;
    padding: 0;
    border: none;
    cursor: pointer;
}

.is-active{
    border: 1px solid black;
}

.icon{
    height: 1.171vw;
    width: 1.171vw;
    /* width: 50px; */
    /* z-index: 100; */
    position: relative;
    top: -6px;
}

/* For Mobile */
@media (max-width: 768px) {
    .toolBarComponent{
        height: 10%;
        max-height: 25px;
        width: 97%;
    }

    .button{
        height: 1.026vw;
        width: 1.026vw;
        position: static;
        background-color: transparent;
        display: inline;
        padding: 0;
        border: none;
        cursor: pointer;
    }

    .icon{
        height: 0.877rem;
        width: 0.877rem;
        /* width: 50px; */
        /* z-index: 100; */
        position: relative;
        top: -6px;
    }
}