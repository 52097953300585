.editorContainer{
    width: 100%;
    height: 100%;
}

.EditorMain{
    height: 100%;
    border:1px solid #000000;
    /* line-height: 4px; */
    padding-top: 4px;
    overflow-y: scroll;
    padding: 4px;
    
}
