.iconButtonStyles {
  background-color: #f5f5f5;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
}
.tooltip{
  display: none;
  position: absolute;
  background-color: #f5f5f5;
  color: #000;
  right: -21px;
  bottom: -34px;
  /* width: 21rem; */
  width: 100vw;
}
.tooltip1{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  /* width: 21rem; */
}
.iconButtonStyles:hover .tooltip{
  display: block;
}