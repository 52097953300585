.billContainer {
    margin-top: 0.625rem;
  }
  
  .billTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #898989;
    border-radius: 0.375rem;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
    padding: 0.75rem;
  }
  
  .billTitles {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
  }
  
  .billAmount {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
  }
  
  .billBottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #898989;
    border-top: unset;
    border-radius: 0.375rem;
    border-top-right-radius: 0%;
    border-top-left-radius: 0%;
    padding: 0.75rem;
    font-size: 1.125rem;
    font-weight: bold;
  }

#userId, #specialization, #skill {
  margin: 0px;
  padding: 0px;
  width: 32.051vw;
  height: 3.077vw;
}

#skill-label, #userId-label{
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  margin-bottom: -20px;
  color: #222222;
}

.customTextfield {
  box-shadow: 0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A
}