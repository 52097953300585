.sidenav-name {
    padding: 1.41vw 0 1.282vw 1.026vw;
    font-size: 1.026VW;
    font-weight: 600;
    line-height: 1.231vw;
    text-align: left;
    color: black;
}

.list-item {
    border: 1px solid;
    border-top-color: #D4E7F7;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: #D4E7F7;
}

.list-item-text {
    font-size: 0.897vw;
}

.sidenav-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

/* For Mobile */
@media (max-width: 768px) {
    .sidenav-name {
        font-size: 0.75rem;
        /* padding: 1.313rem 1.063rem; */
    }

    .list-item {
        font-size: 0.688rem;
    }

    .list-item-text {
        font-size: 0.75rem;
    }

    .sidenav-top {
        justify-content: space-around;
        gap: 0;
    }
}