.filterForm {
    display: flex;
    align-items: center;
    justify-content: end;
}
.thfirstChild{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px !important;
}
.thlastChild{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px !important;
}
.paraNoMarginPadding {
    margin: 0;
    padding: 0px !important;
}
/* Mobile CSS */
/* .menuMobile{
    display: grid;
    grid-template-columns: 1fr 100px;
    padding: 5px 8px;
} */

.iconButtonStyles {
    background-color: #f5f5f5;
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
  }
  .tooltip{
    display: none;
    position: absolute;
    background-color: #f5f5f5;
    color: #000;
    right: -21px;
    bottom: -75px;
    width: 381px;
    /* width: 100vw; */
  }
  .tooltip1{
    display: grid;
    grid-template-columns: 1fr 100px;
    justify-items: start;
    padding: 4px;
    /* width: 21rem; */
    border-radius: 4px;
  }
  .iconButtonStyles:hover .tooltip{
    display: block;
  }